import React from 'react';
import img from '../../../../images/NFTS/Group 71.png';
import img2 from '../../../../images/NFTS/Group 72.png';
import img3 from '../../../../images/NFTS/Group 73.png';
import img4 from '../../../../images/NFTS/Group 74.png';

function Nftarts() {
  return (
    <section className="max-w-7xl mx-auto px-8 sm:px-16 mt-[90px] ml-[150px] xl:ml-[200px] 3xl:ml-[300px]">
      <div className=" flex space-x-3 overflow-scroll scrollbar-hide p-16 -ml-[200px] xl:-ml-[220px]">
        <div className="cursor-pointer hover:scale-105 transition transform duration-300 ease-out">
          <div className="relative h-80 w-80 ">
            <img src={img} className="w-[400px]" alt="" />
          </div>
          <h3 className="text-2xl mt-3">Hello</h3>
        </div>
        <div className="cursor-pointer hover:scale-105 transition transform duration-300 ease-out">
          <div className="relative h-80 w-80 ">
            <img src={img2} className="w-[400px]" alt="" />
          </div>
          <h3 className="text-2xl mt-3">Hello</h3>
        </div>
        <div className="cursor-pointer hover:scale-105 transition transform duration-300 ease-out">
          <div className="relative h-80 w-80 ">
            <img src={img3} className="w-[400px]" alt="" />
          </div>
          <h3 className="text-2xl mt-3">Hello</h3>
        </div>
        <div className="cursor-pointer hover:scale-105 transition transform duration-300 ease-out">
          <div className="relative h-80 w-80 ">
            <img src={img4} className="w-[400px]" alt="" />
          </div>
          <h3 className="text-2xl mt-3">Hello</h3>
        </div>
        {/* <div className="cursor-pointer hover:scale-105 transition transform duration-300 ease-out">
          <div className="relative h-80 w-80 ">
            <img src={img} className="w-[400px]" alt="" />
          </div>
          <h3 className="text-2xl mt-3">Hello</h3>
        </div>
        <div className="cursor-pointer hover:scale-105 transition transform duration-300 ease-out">
          <div className="relative h-80 w-80 ">
            <img src={img2} className="w-[400px]" alt="" />
          </div>
          <h3 className="text-2xl mt-3">Hello</h3>
        </div> */}
      </div>
    </section>
  );
}

export default Nftarts;
