import './App.css';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
// import AboutMattr from './components/HomePage/AboutMattr/AboutMattr';
import Contact from './components/HomePage/Contact/Contact';
import HomeSection from './components/HomePage/HomeSection/HomeSection';
// import Metaverse from './components/HomePage/Metaverse/Metaverse';
import Nft from './components/HomePage/NFT/Nft';
// import Projects from './components/HomePage/Projects/Projects';
// import SuggestedStuffs from './components/HomePage/SuggestedStuffs/SuggestedStuffs';
// import SuggestionVideos from './components/HomePage/SuggestionVideos/SuggestionVideos';
// import Websites from './components/HomePage/Websites/Websites';

function App() {
  return (
    <div className="app">
      <Header />
      <HomeSection />
      {/* <AboutMattr />
      <Metaverse />
      <Websites /> */}
      <Nft />
      {/* <Projects />
      <SuggestionVideos />
      <SuggestedStuffs /> */}
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
