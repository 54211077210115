import React from 'react';

function Contact() {
  return (
    <div className="items-center text-center justify-center mt-[-200px] space-y-6">
      <h1 className="text-white text-5xl xl:text-8xl">Want to Join?</h1>
      <button
        data-tf-popup="lNH71uNL"
        data-tf-iframe-props="title=Mattr"
        data-tf-medium="snippet"
        className="bg-yellow-400 text-black py-4 px-16 rounded-md"
      >
        Count Me In! 🤩
      </button>
    </div>
  );
}

export default Contact;
