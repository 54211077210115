import React from 'react';
import Logo from '../../../images/logo.png';

function HomeSection() {
  return (
    <div className=" xl:ml-[60px] 2xl:ml-[20px] mt-[120px] xl:mt-[120px] 2xl:mt-[150px] xl:mb-[800px]">
      <div className="grid grid-rows-3 justify-items-center space-y-[-30px] lg:space-y-4 xl:space-y-[-40px] ">
        <h1 className="text-xl xl:text-4xl text-center text-[#BEBAFF] font-[RoganRegular]">
          THE ULTIMATE PLATFORM CONNECTING
          <br></br>
          WEB2 & WEB3
        </h1>
        <h1 className="text-2xl xl:text-6xl text-white py--120">
          HERE'S THE NEXT BIG THING
        </h1>
        <div>
          <img
            src={Logo}
            className="w-[200px] lg:w-[300px] xl:ml-[-8px]"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default HomeSection;
