import React from 'react';
import './Nft.css';
import Nftarts from './Nftarts/Nftarts';

function Nft() {
  return (
    <div className="mt-[300px] lg:mt-[50px] text-center mb-[300px]">
      <div className="nline space-y-[-20px] xl:space-y-[-40px]">
        <h1 className="text-[150px] text-black lg:text-[300px]">NFTS</h1>
        <p className="text-[30px] lg:text-[30px]">
          This is our first step in the Web3 world.
        </p>
      </div>
      <Nftarts />{' '}
      <div className="items-center ml-[120px] xl:ml-[650px] 2xl:ml-[700px] 3xl:ml-[800px]">
        <lottie-player
          src="https://assets4.lottiefiles.com/packages/lf20_auny4ili.json"
          background="transparent"
          speed="1"
          className=""
          style={{ width: '150px', marginBottom: '10px' }}
          loop
          autoplay
        ></lottie-player>
      </div>
    </div>
  );
}

export default Nft;
