import React, { useState } from 'react';
// import Logo from '../../images/logo.svg';
import Logo from '../../images/logo.png';
import { Transition } from '@headlessui/react';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="max-w-5xl py-8 px-16 xl:ml-[233px] 2xl:ml-[250px] 3xl:ml-[340px]">
      <nav className="hidden xl:block sticky top-[5px]  z-50 bg-white shadow-md py-[10px] px-5 md:px-10 rounded-lg backdrop-filter backdrop-blur-xl bg-opacity-5">
        <div className="grid grid-cols-3 lg:grid-cols-5 items-center justify-items-center text-white">
          <h1 className="font-[RoganRegular]">Development</h1>
          <h1 className="font-[RoganRegular]">Products</h1>
          <img src={Logo} className="w-[80px]" alt="" />
          <h1 className="font-[RoganRegular]">NFT</h1>
          <h1 className="font-[RoganRegular]">MetaVerse</h1>
        </div>
      </nav>
      <nav className="sm:block xl:hidden  sticky top-[5px]  z-50  py-[10px] px-5 md:px-10 rounded-lg">
        <div className="grid grid-cols-2 space-x-[200px] items-center justify-items-center text-white text-center">
          {/* <div className="space-y-3">
            <h1 className="font-[RoganRegular] text-sm">Development</h1>
            <h1 className="font-[RoganRegular] text-sm">Products</h1>
          </div> */}
          <img src={Logo} className="w-[40px] ml-[-160px]" alt="" />
          <div className="-mr-3 xs:-mr-2 flex lg:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
          {/* <div className="space-y-3">
            <h1 className="font-[RoganRegular] text-sm">NFT</h1>
            <h1 className="font-[RoganRegular] text-sm">MetaVerse</h1>
          </div> */}
        </div>
      </nav>
      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref) => (
          <div className="lg:hidden" id="mobile-menu">
            <div ref={ref} className="px-1 pt-2 pb-3 space-y-1 sm:px-1">
              <a
                href="/"
                className="text-white ml-[-24px] py-2 text-sm font-medium font-[RoganRegular]"
              >
                Development
              </a>
              <a
                href="/"
                className="text-white block ml-[-24px] py-2 text-sm font-medium font-[RoganRegular]"
              >
                Products
              </a>

              <a
                href="/"
                className="text-white block ml-[-24px] py-2 text-sm font-medium font-[RoganRegular]"
              >
                NFT
              </a>
              <a
                href="/"
                className="text-white block ml-[-24px] py-2 text-sm font-medium font-[RoganRegular]"
              >
                MetaVerse
              </a>
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
}

export default Header;
