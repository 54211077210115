import React from 'react';

function Footer() {
  return (
    <div className="bg-black h-[200px] xl:h-[180px]  items-center text-center justify-items-center  ">
      {/* <div className="grid grid-cols-1 xl:grid-cols-2 py-16 px-8 items-center gap-x-[100px]">
        <div className="grid grid-cols-1 md:grid-cols-3 ">
          <div className="space-y-4 text-lg text-white">
            <h5 className="font-bold">ABOUT</h5>
            <p>How Airbnb works</p>
            <p>Newsroom</p>
            <p>Investors</p>
            <p>Airbnb Plus</p>
            <p>Airbnb Luxe</p>
          </div>
          <div className="space-y-4 text-lg text-white">
            <h5 className="font-bold">ABOUT</h5>
            <p>How Airbnb works</p>
            <p>Newsroom</p>
            <p>Investors</p>
            <p>Airbnb Plus</p>
            <p>Airbnb Luxe</p>
          </div>
          <div className="space-y-4 text-lg text-white">
            <h5 className="font-bold">ABOUT</h5>
            <p>How Airbnb works</p>
            <p>Newsroom</p>
            <p>Investors</p>
            <p>Airbnb Plus</p>
            <p>Airbnb Luxe</p>
          </div>
        </div>
        <div className="mt-[100px] ml-[0] lg:ml-[180px] w-[600px] space-y-5">
          <h1 className="text-white text-3xl lg:text-5xl">
            Be the first to know
          </h1>
          <button className="text-black bg-yellow-500 py-4 px-[10px] lg:px-[30px]">
            JOIN OUR NEWSLETTER
          </button>
        </div>
      </div> */}
      <div className="mt-[140px] xl:grid-cols-2 text-center py-16 px-8 gap-x-[500px]  xl:mb-[-800px]">
        <div className="">
          <h1 className="text-gray-300">
            Copyright &copy; 2022 Mattr. All Rights Reserved.
            <br /> Made with <i className="fa fa-heart text-red-600" /> by
            Mattr.
          </h1>
        </div>
        {/* <div className="grid grid-cols-1 lg:grid-cols-2 mt-[30px] lg:mt-[0] xl:ml-[80px] gap-x-[220px]">
          <h1 className="text-gray-300 w-[300px]">Terms & Conditions</h1>
          <h1 className="text-gray-300">Policy</h1>
        </div> */}
      </div>
    </div>
  );
}

export default Footer;
